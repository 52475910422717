import Uppy, { UppyOptions } from '@uppy/core'
import AwsS3, { AwsS3Options } from '@uppy/aws-s3'
import Dashboard, { DashboardOptions } from '@uppy/dashboard'
import ImageEditor, { ImageEditorOptions } from '@uppy/image-editor'
import Webcam, { WebcamOptions } from '@uppy/webcam'
import French from '@uppy/locales/lib/fr_FR'
import createHttpClient from '@/api/httpClient'
import i18n from '@/plugins/i18n'

import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'
import '@uppy/image-editor/dist/style.min.css'
import '@uppy/webcam/dist/style.min.css'

const uppyOptions: UppyOptions = {
  debug: true,
  locale: {
    ...French,
    strings: {
      ...French.strings,
      takePicture: 'Caméra', // Forcer la traduction correcte
    },
  },
  restrictions: {
    allowedFileTypes: ['image/jpeg', 'image/png', 'image/gif'],
    maxFileSize: 30 * 1024 * 1024, // 5MB
    maxNumberOfFiles: 5,
  },
}

const uppyDashboardOptions: DashboardOptions = {
  inline: false,
  target: '#dashboard-uppy',
  hideUploadButton: false,
  proudlyDisplayPoweredByUppy: false,
}

const uppyImageEditorOptions: ImageEditorOptions = {
  target: Dashboard,
}

const uppyWebcamOptions: WebcamOptions = {
  target: Dashboard,
  modes: ['picture'],
}

const uppyS3Options: AwsS3Options = {
  shouldUseMultipart: false,
  getUploadParameters(file) {
    const httpClient = createHttpClient()
    return httpClient
      .post('/file/presign', {
        name: file.name,
        contentType: file.type,
        size: file.size,
      })
      .then(response => {
        const { data } = response
        file.meta.key = data.key
        return {
          method: data.method,
          url: data.url,
          fields: data.fields,
          headers: {},
        }
      })
  },
}

const PhotoService = new Uppy(uppyOptions)
  .use(Dashboard, uppyDashboardOptions)
  .use(ImageEditor, uppyImageEditorOptions)
  .use(Webcam, uppyWebcamOptions)
  .use(AwsS3, uppyS3Options)

const setMaxNumberOfFiles = (maxNumberOfFiles: number) => {
  uppyOptions.restrictions.maxNumberOfFiles = maxNumberOfFiles
  PhotoService.setOptions(uppyOptions)
  uppyDashboardOptions.note = `Max. ${maxNumberOfFiles} ${i18n.global.t('photoService.photo', maxNumberOfFiles)}`
  PhotoService.getPlugin('Dashboard').setOptions(uppyDashboardOptions)
}

export default PhotoService
export { setMaxNumberOfFiles }
