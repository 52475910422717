<script setup lang="ts">
import { onMounted, computed, ref } from 'vue'
import { useSubscriptionStore } from '@/store/subscription/subscriptionStore'
import createHttpClient from '@/api/httpClient'

import { useI18n } from 'vue-i18n'

const { t: $t, d, n } = useI18n()
const subscriptionStore = useSubscriptionStore()
const httpClient = createHttpClient()

const paymentHeaders = [
  { title: 'Date:', value: 'createdAt', align: 'left' },
  { title: "Type D'abonnement:", value: 'paymentType', align: 'left' },
  { title: 'Montant', value: 'amount', align: 'center' },
  { title: 'Statut', value: 'status', align: 'center' },
  { title: 'Téléchargement', value: 'invoice', align: 'center' },
] as any

const paymentList = computed(() => {
  return subscriptionStore.paymentList
})

const getPaymentStatus = status => {
  switch (status) {
    case 'succeeded':
    case 'partially_refunded':
      return {
        text: $t('dashboard.subscription.payments.status.succeeded'),
        color: '#12C29B1A',
        code: 'secondary',
      }
    case 'pending':
    case 'failed':
      return {
        text: $t('dashboard.subscription.payments.status.pending'),
        color: '#FFC1071A',
        code: 'accent',
      }
    case 'refunded':
      return {
        text: $t('dashboard.subscription.payments.status.refunded'),
        color: '#FFE8E8',
        code: 'error',
      }
    case 'failed':
    default:
      return {
        text: $t('dashboard.subscription.payments.status.failed'),
        color: '#FFE8E8',
        code: 'error',
      }
  }
}

const formattedPaymentList = computed(() => {
  return subscriptionStore.paymentList.map(payment => {
    return {
      ...payment,
      createdAt: d(new Date(payment.createdAt), 'short'),
      amount: n(payment.amountCents / 100, 'currency'),
      status: getPaymentStatus(payment.status).text,
      statusColor: getPaymentStatus(payment.status).color,
      statusCode: getPaymentStatus(payment.status).code,
      paymentType: 'Référencement Holitime',
    }
  })
})

const donwloading = ref({})
const paymentItemsPerPage = 10
const downloadInvoice = async (uuid: string) => {
  if (donwloading.value[uuid]) return false
  try {
    donwloading.value = { ...donwloading.value, [uuid]: true }

    const { data } = await httpClient.get(`/payment/download-receipt/${uuid}`, {
      responseType: 'blob',
    })

    const link = document.createElement('a')
    link.href = URL.createObjectURL(data)
    link.download = 'Abonnement Holitime.pdf'
    document.body.appendChild(link) // Nécessaire pour Firefox
    link.click()

    setTimeout(() => {
      URL.revokeObjectURL(link.href)
    }, 100)
  } catch (err) {
  } finally {
    donwloading.value = { ...donwloading.value, [uuid]: false }
  }
}

const onPaymentTableUpdate = async ({ page, itemsPerPage }) => {
  await subscriptionStore.fetchPayments(page, itemsPerPage)
}
</script>

<template>
  <div class="mt-8 d-flex flex-column ga-3">
    <span class="text-dark-green font-weight-medium">{{
      $t('billing_payment')
    }}</span>
    <v-data-table-server
      class="bg-white"
      :items="formattedPaymentList"
      :headers="paymentHeaders"
      :items-per-page="paymentItemsPerPage"
      :items-length="subscriptionStore.paymentTotalItems"
      :items-per-page-options="[10, 20, 30]"
      :loading="subscriptionStore.paymentListLoading"
      :no-data-text="$t('dashboard.subscription.payments.noPayment')"
      @update:options="onPaymentTableUpdate"
    >
      <template #item.status="{ item }">
        <span
          :style="{ backgroundColor: item.statusColor }"
          class="border-md border-opacity-100 px-4 py-1 rounded-pill"
          :class="[`text-${item.statusCode}`, `border-${item.statusCode}`]"
          >{{ item.status }}</span
        >
      </template>
      <template #item.paymentType="{ item }">
        <span class="holitime-ref">{{ item.paymentType }}</span>
      </template>
      <template #item.invoice="{ item }">
        <div class="d-flex justify-center">
          <v-btn
            v-if="item.statusCode != 'error'"
            class="download-btn pa-4"
            rounded="lg"
            :loading="donwloading[item.id]"
            @click="() => downloadInvoice(item.id)"
            density="compact"
            color="#F5F5F5"
          >
            <div class="d-flex ga-2">
              <span>{{ $t('download') }}</span>
              <img
                @click="() => downloadInvoice(item.id)"
                v-if="item.statusColor != 'error'"
                src="@/assets/download.png"
              />
            </div>
          </v-btn>
          <span v-else>-</span>
        </div>
      </template>
    </v-data-table-server>
  </div>
</template>

<style scoped lang="scss">
.header {
  font-size: 16px;
}

.download-btn {
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
}

.download-btn span {
  color: #616161;
  font-size: 14px;
}

.holitime-ref {
  color: #029978;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
}

:deep() {
  .v-data-table__thead {
    background-color: #f5f5f5 !important;
    padding: 20px, 32px, 20px, 32px !important;
    border-radius: 10px;
    color: #616161;
    font-size: 16px;
  }

  .v-table__wrapper {
    border-radius: 15px !important;
  }
}
</style>
